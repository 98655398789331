import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  menu: {
    display: 'flex',
    backgroundColor: '#212429',
    color: 'white',
    width: 250,
    '& > *:last-child': {
      borderTop: '1px solid rgba(255, 255, 255, .3)'
    }
  },
  header: {
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  menuList: {
    flex: 1
  },
  avatar: {
    height: 85,
    width: 85,
    border: `2px solid ${theme.palette.primary.contrastText}`,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(6),
    boxShadow: '2px 2px 6px 1px rgba(0, 0, 0, 0.2)'
  },
  userName: {
    padding: theme.spacing(0, 2),
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium
  },
  userBalance: {
    marginTop: theme.spacing()
  },
  notificationTitle: {
    display: 'flex',
    justifyContent: 'center'
  },
  notificationIcon: {
    marginRight: theme.spacing(2)
  },
  notification: {
    display: 'block',
    padding: theme.spacing(.3, 0),
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.grey[400],
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.contrastText
    }
  },
  listIcon: {
    minWidth: 36
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, .12)'
    }
  }
}));