import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from 'components/Button';
import useStyles from './styles';

function ConfirmationModal ({ open, title, description, type, size, confirmLabel, onConfirm, cancelLabel, onClose, icon }) {
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const classes = useStyles();

  const invalid = useMemo(() => !!type && type !== value, [type, value]);
  const Icon = useMemo(() => {
    switch (icon) {
      case 'success':
        return CheckCircleIcon;
      default:
        return null;
    }
  }, [icon]);

  const handleClose = useCallback(evt => {
    if (!isLoading) {
      setValue('');
      setErrorMessage(null);
      onClose(evt);
    }
  }, [isLoading, onClose]);

  const handleConfirm = useCallback(async () => {
    if (!onConfirm) {
      return onClose();
    }
    setErrorMessage(null);
    setIsLoading(true);
    try {
      await onConfirm();
      handleClose();
    } catch (err) {
      setErrorMessage(err?.message);
    }
    setIsLoading(false);
  }, [handleClose, onClose, onConfirm]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={!!size}
      maxWidth={size}
      aria-labelledby="alert-title"
      aria-describedby="alert-description"
    >
      {!!title && <DialogTitle id="alert-title" className={classes.header}>{title}</DialogTitle>}
      <DialogContent>
        <div className={classes.content}>
          {!!Icon && <Icon className={classes.icon} />}
          {!!description && (
            <DialogContentText className={classes.description} id="alert-description">
              {description}
            </DialogContentText>
          )}
        </div>
        {!!type && (
          <TextField
            value={value}
            onChange={e => setValue(e.target.value)}
            autoFocus
            fullWidth
            id="confirm"
            autoComplete="off"
            disabled={isLoading}
            placeholder={`para confirmar digite '${type}' sem aspas`}
          />
        )}
        {!!errorMessage && (
          <Box mt={2}>
            <Alert severity="error">{errorMessage}</Alert>
          </Box>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {!!cancelLabel && (
          <Button onClick={handleClose} disabled={isLoading}>
            {cancelLabel}
          </Button>
        )}
        {!!confirmLabel && (
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            autoFocus={!type}
            disabled={invalid}
            loading={isLoading}
            size="large"
          >
            {confirmLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  onConfirm: PropTypes.func,
  description: PropTypes.node,
  type: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  icon: PropTypes.oneOf(['success']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])
};

ConfirmationModal.defaultProps = {
  confirm: 'Confirmar',
  size: 'xs'
};

export default ConfirmationModal;
