import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './styles';
import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';

const StyledButton = styled(Button)(compose(spacing));

export default function LoadingButtonComponent ({ loading, disabled, children, ...props }) {
  const SIZE = 24;
  const classes = useStyles({ size: SIZE });

  return (
    <StyledButton {...props} disabled={loading || disabled}>
      {children}
      {loading && <CircularProgress size={SIZE} className={classes.progress} />}
    </StyledButton>
  );
};

LoadingButtonComponent.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};
