import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';

function SlideCarouselCountdownComponent ({ duration, interval, stop, ...props }) {
  const [countdown, setCountdown] = useState(0);

  const durationInSeconds = duration * 1000;
  const isCounting = countdown > 0;

  useEffect(() => setCountdown(durationInSeconds), [durationInSeconds, stop]);

  useEffect(() => {
    if (isCounting && !stop) {
      const timer = setInterval(() => setCountdown(prev => Math.max(prev - interval, 0)), interval);
      return () => clearInterval(timer);
    }
  }, [interval, isCounting, stop]);

  if (!duration) {
    return null;
  }

  const width = (durationInSeconds - countdown) / durationInSeconds * 100;

  return (
    <div {...props} style={{ width: `${width}%` }} />
  );
}

SlideCarouselCountdownComponent.propTypes = {
  duration: PropTypes.number.isRequired,
  interval: PropTypes.number.isRequired,
  stop: PropTypes.bool
};

SlideCarouselCountdownComponent.defaultProps = {
  duration: 0,
  interval: 50
};

export default memo(SlideCarouselCountdownComponent);
