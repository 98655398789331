import React, { createContext, useContext, useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const IsOnlineContext = createContext();

export const IsOnlineProvider = props => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      <IsOnlineContext.Provider {...props} value={isOnline} />
      <Dialog open={!isOnline}>
        <DialogContent>
          <DialogContentText>
            Você está offline no momento e não pode continuar acompanhando as partidas.
            {' '}Mas não se preocupe seus prêmios estão garantidos mesmo assim.
            {' '}Caso sua conexão retorne essa mensagem vai desaparecer.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

const useIsOnline = () => {
  const context = useContext(IsOnlineContext);

  if (context === undefined) {
    throw new Error('useIsOnline must be used within a IsOnlineProvider');
  }

  return context;
};

export default useIsOnline;
