import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'clsx';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import { times } from 'helpers';

function BingoCardComponent ({ data, small, lengthY, lengthX, balls, type, ...other }) {
  const classes = useStyles({ lengthX, small, type });
  const matrix = useMemo(() => {
    const _matrix = times(lengthY, 0, () => times(lengthX, 0, ''));
    for (const { positionY, positionX, value } of data?.CardNumbers) {
      _matrix[positionY][positionX] = String(value);
    }
    return _matrix;
  }, [lengthY, lengthX, data?.CardNumbers]);

  if (!data) {
    return null;
  }

  const { id, profileName, cardSeries } = data;

  return (
    <Box {...other}>
      <div className={classes.header}>
        <Typography className={classes.code}>{id}</Typography>
        {profileName ? (
          <Typography className={classes.user}>{profileName}</Typography>
        ) : (
          <Typography className={classes.series}>Série: <strong>{cardSeries}</strong></Typography>
        )}
      </div>
      <ul className={classes.body}>
        {matrix.map((rows, y) => (
          rows.map((value, x) => (
            <li key={`${y}_${x}`}>
              <Typography className={classnames(
                classes.ball,
                value && balls?.includes(value) && classes.active
              )}>
                {value}
              </Typography>
            </li>
          ))
        ))}
      </ul>
    </Box>
  );
}

BingoCardComponent.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.any.isRequired,
    profileName: PropTypes.string,
    cardSeries: PropTypes.number.isRequired,
    CardNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        positionX: PropTypes.number.isRequired,
        positionY: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired
      })
    )
  }),
  lengthX: PropTypes.number.isRequired,
  lengthY: PropTypes.number.isRequired,
  balls: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.oneOf(['quadra', 'bingo', 'linha', 'acumulado']),
  small: PropTypes.bool
};

BingoCardComponent.defaultProps = {
  lengthX: 9,
  lengthY: 3
};

export default memo(BingoCardComponent);
