import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SignOutIcon from '@material-ui/icons/ExitToApp';
import useAuth from 'hooks/useAuth';
import { Link } from 'react-router-dom';
import Share from 'components/Share';
import AppBar from 'components/AppBar';
import Header from './Header';
import Notifications from './Notifications';
import useStyles from './styles';

export default function SideMenuComponent ({ data, children }) {
  const classes = useStyles();
  const { currentUser, isLoading, signOut } = useAuth();
  const [open, setOpen] = useState(false);

  const toggleDrawer = useCallback(to => () => setOpen(prev => to ?? !!prev), []);

  const handleLogout = useCallback(() => {
    signOut();
    setOpen(false);
  }, [signOut]);

  return (
    <>
      <AppBar onClick={toggleDrawer(true)} />
      {children}
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{ className: classes.menu }}
      >
        <Header onClick={toggleDrawer(false)} />

        <Notifications data={currentUser?.notifications} onClick={toggleDrawer(false)} />

        <List className={classes.menuList}>
          {data?.map(({ key, path, label, icon }) => (
            <ListItem
              key={key}
              button
              component={Link}
              to={path}
              onClick={toggleDrawer(false)}
              className={classes.listItem}
            >
              {!!icon && (
                <ListItemIcon className={classes.listIcon}>
                  {icon}
                </ListItemIcon>
              )}
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>

        <Share px={2} py={1} />

        {!!currentUser && (
          <List>
            <ListItem
              className={classes.listItem}
              button onClick={handleLogout}
              disabled={isLoading}
            >
              <ListItemIcon className={classes.listIcon}>
                <SignOutIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Sair" />
            </ListItem>
          </List>
        )}
      </SwipeableDrawer>
    </>
  );
}

SideMenuComponent.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      icon: PropTypes.element
    })
  )
};
