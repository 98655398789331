import React, { createContext, useContext, useState, useCallback } from 'react';

const AppBarContext = createContext();

export const AppBarProvider = props => {
  const [data, setData] = useState({});

  const setAppBar = useCallback(({ parent, callToAction, header, title }) => {
    setData({ parent, callToAction, header, title });
    return () => setData();
  }, []);

  return (
    <AppBarContext.Provider {...props} value={{ ...data, setAppBar }} />
  );
};

const useAppBar = () => {
  const context = useContext(AppBarContext);

  if (context === undefined) {
    throw new Error('useAppBar must be used within a AppBarProvider');
  }

  return context;
};

export default useAppBar;
