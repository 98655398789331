import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: {
    padding: theme.spacing(),
    borderRadius: 8,
    margin: 4,
    width: '3em',
    textAlign: 'center',
    border: 0,
    height: ({ large }) => large ? 43 : 33,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: ({ large }) => large ? theme.typography.pxToRem(18) : theme.typography.pxToRem(16),
    border: '1px solid #D2D2D2',
    color: theme.palette.primary.dark
  },
  button: {
    cursor: 'pointer',
    borderRadius: 8,
    height: ({ large }) => large ? 43 : 33,
    width: ({ large }) => large ? 43 : 33,
    margin: 4,
    padding: 0,
    fontFamily: theme.typography.fontFamily,
    border: 'none',
    backgroundColor: theme.palette.grey[300],
    lineHeight: 1,
    background: 'none',
    '&:focus': {
      outline: 'none'
    },
    '&:disabled': {
      opacity: .5
    },
    '& > .MuiSvgIcon-root': {
      display: 'block',
      margin: 'auto',
      color: theme.palette.text.primary,
      fontSize: ({ large }) => large ? theme.typography.pxToRem(22) : theme.typography.pxToRem(18)
    }
  },
  max: {
    marginRight: 4,
    color: theme.palette.text.secondary
  }
}));
