import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Center from 'components/Center';

export default function LoaderComponent ({ forceCenter, ...props }) {
  return (
    <Center {...props} force={forceCenter}>
      <CircularProgress />
    </Center>
  );
}

LoaderComponent.propTypes = {
  forceCenter: PropTypes.bool
};
