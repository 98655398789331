import React, { useMemo, memo, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'clsx';
import useTheme from '@material-ui/core/styles/useTheme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { toMoney } from 'helpers';
import Card from 'components/Bingo/Card';
import Strip from 'components/Strip';
import Carousel from 'components/SlideCarousel';
import useStyles from './styles';
import useBingoAccumulated from 'hooks/useBingoAccumulated';

const getLabel = count => count === 1 ? '1 cartela vencedora!' : `${count} cartelas vencedoras!`;

const COLORS = {
  quadra: { main: '#fe5418', dark: '#dc3f17', black: '#b2280b' },
  linha: { main: '#0d7ad9', dark: '#0c68b9', black: '#0a4c86' },
  bingo: { main: '#12a60d', dark: '#118d0d', black: '#0a7407' },
  acumulado: { main: '#E8C900', dark: '#DBBE00', black: '#C2A900' }
};

function BingoWinnersComponent ({ open, data, delay }) {
  const { updateAccumulated } = useBingoAccumulated();

  useEffect(() => {
    if (data?.name == 'Acumulado') {
      updateAccumulated(data);
    }
  },[open, data, updateAccumulated]);

  const prizeType = useMemo(() => data?.name?.toLowerCase(), [data]);
  const classes = useStyles({ prizeType });
  const sortedBalls = useMemo(() => data?.Match?.sortedBalls?.split(','), [data]);
  const theme  = useTheme();
  const colors = COLORS[prizeType];

  return (
    <Dialog open={open} classes={{ root: classes.root, paper: classes.overflow }}>
      <DialogContent className={classnames(classes.content, classes.overflow, classes[prizeType])}>
        <div className={classes.header}>
          <Strip {...colors} height={70} />
          <Typography className={classes.title}>{data?.name}</Typography>
        </div>
        <Typography className={classes.label}>{getLabel(data?.cardAwards?.length)}</Typography>
        <Carousel width={350} mt={2} margin={3} delay={delay} color={theme.palette.custom[prizeType]?.card} forceAutoNavigate>
          {data?.cardAwards?.map(card => (
            <Fragment key={card.id}>
              <Typography className={classes.prize}>{toMoney(card.award)}</Typography>
              <Card data={card.matchCard} balls={sortedBalls} type={prizeType} />
            </Fragment>
          ))}
        </Carousel>
      </DialogContent>
    </Dialog>
  );
};

BingoWinnersComponent.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    award: PropTypes.number.isRequired,
    Match: PropTypes.shape({
      sortedBalls: PropTypes.string.isRequired
    }).isRequired,
    cardAwards: PropTypes.arrayOf(
      PropTypes.shape({
        matchCard: PropTypes.object.isRequired
      })
    ).isRequired
  }),
  delay: PropTypes.number
};

BingoWinnersComponent.defaultProps = {
  delay: 3
};

export default memo(BingoWinnersComponent);
