import React, { useState, useCallback, useEffect, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import Auth from 'config/auth';
import pusher from 'config/push';
import { Authenticator } from 'aws-amplify-react';
import Provider from './index';
import axios from 'axios';
const currentURL = window.location.hostname;
const BackOfficeUrl =
  currentURL == 'localhost' ?  'https://i5qy580i01.execute-api.us-east-1.amazonaws.com/v1' :
    currentURL == 'pnr-web-dev.s3-website-us-east-1.amazonaws.com' ? 'https://i5qy580i01.execute-api.us-east-1.amazonaws.com/v1' :
      'https://rws1xbxfkf.execute-api.us-east-1.amazonaws.com/v1';
const bodyRequest =
  currentURL == 'localhost' ?  { 'phoneNumber': '+5521973138770', 'password': 'q1w2e3r4' } :
    currentURL == 'pnr-web-dev.s3-website-us-east-1.amazonaws.com' ? { 'phoneNumber': '+5521973138770', 'password': 'q1w2e3r4' } :
      { 'phoneNumber': '+5521999988888', 'password': 'devell@2021' };
const NAMESPACE = '@PNR:';
const TOKEN = 'TOKEN';
const Storage = window.localStorage;
export function getData (name) {
  return Storage.getItem(`${NAMESPACE}${name}`);
}
export function setData (name, value) {
  Storage.setItem(`${NAMESPACE}${name}`, value);
  return value;
}
export function clearData (name) {
  Storage.removeItem(`${NAMESPACE}${name}`);
}
export const getToken = () => getData(TOKEN);
export const setToken = value => setData(TOKEN, value);
export const clearToken = () => clearData(TOKEN);
const Provider2 = axios.create({
  baseURL: BackOfficeUrl,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});
const authContext = createContext();
const i18n = message => {
  if (/incorrect.*username.*password/i.test(message)) {
    return 'Usuário ou senha incorretos';
  }
  return message;
};
const useAuth = () => {
  const context = useContext(authContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
export function AuthProvider ({ children }) {
  const auth = useAuthProvider();
  return (
    <Authenticator hideDefault>
      <authContext.Provider value={auth}>
        {auth.currentUser === undefined ? <Loader forceCenter />  : children}
      </authContext.Provider>
    </Authenticator>
  );
}
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
function useAuthProvider () {
  const [loading, setLoading] = useState({});
  const [error, setError] = useState({});
  const [currentUser, setCurrentUser] = useState();
  const [userChannel, setUserChannel] = useState();
  const execute = useCallback(async (method, callback) => {
    setLoading(prev => ({ ...prev, [method]: true }));
    setError(prev => ({ ...prev, [method]: null }));
    try {
      return await callback();
    } catch (err) {
      const message = i18n(err.message);
      setError(prev => ({ ...prev, [method]: message }));
      throw err;
    } finally {
      setLoading(prev => ({ ...prev, [method]: false }));
    }
  }, []);
  const me = useCallback(async () => {
    try {
      const user = await Provider.get('/me');
      setCurrentUser(user);
    } catch (err) {
      setCurrentUser(null);
    }
  }, []);
  const signIn = useCallback(async ({ phoneNumber, password }) => (
    execute('signIn', async () => {
      const token = await Provider2.post('/backoffice/auth', bodyRequest);
      setToken(token.data);
      const response = await Auth.signIn(phoneNumber, password);
      await me();
      return response;
    })
  ), [execute, me]);
  const signUp = useCallback(async ({ name, phoneNumber, password, birthDate }) => (
    execute('signUp', async () => {
      const promoCode = window.location.href.split('cadastro')[1].length > 0 ? window.location.href.split('cadastro')[1].replace('/', '') : null;
      await Provider.post('/users', { phoneNumber, password, name, birthdate: birthDate, promocode: promoCode }, { public: true });
      return await signIn({ phoneNumber, password });
    })
  ), [execute, signIn]);
  const recoverPassword = useCallback(async phoneNumber => (
    execute('recoverPassword', async () => {
      return await Provider.post('/users/recoverPassword', { phoneNumber }, { public: true });
    })
  ), [execute]);
  const changePassword = useCallback(async ({ phoneNumber, code, password }) => (
    execute('changePassword', async () => {
      await Provider.post('/users/changePassword', { phoneNumber, code, password }, { public: true });
      return await signIn({ phoneNumber, password });
    })
  ), [execute, signIn]);
  const signOut = useCallback(async () => (
    execute('signOut', async () => {
      await Auth.signOut();
      setCurrentUser(null);
      clearToken();
    })
  ), [execute]);
  useEffect(() => me(), [me]);
  useEffect(() => {
    if (currentUser?.id) {
      const channelName = `user-${currentUser.id}`;
      const channel = pusher.subscribe(channelName);
      channel.bind('walletChange', user => setCurrentUser(user));
      if (process.env.NODE_ENV === 'development') {
        channel.bind_global((eventName, data) => {
          console.log('user', channelName, eventName, data);
        });
      }
      setUserChannel(channel);
      return () => pusher.unsubscribe(channelName);
    }
  }, [currentUser?.id]);
  return {
    loading,
    error,
    currentUser,
    userChannel,
    signIn,
    signUp,
    recoverPassword,
    changePassword,
    signOut
  };
}
export default useAuth;