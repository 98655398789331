import { lazy } from 'react';

export const home = '/carteira';
export const deposit = `${home}/depositar`;
export const withdrawal = `${home}/sacar`;
export const charge = `${home}/recarga`;
export const purchases = `${home}/compras`;
export const transactions = `${home}/transacoes`;
export const movements = `${home}/movimentacoes`;
export const account = `${home}/conta`;
export const preferences = `${home}/preferencias`;
export const paths = [home, deposit, withdrawal, charge, purchases, transactions, movements, account, preferences];

const routes = [
  {
    key: 'deposit',
    path: deposit,
    component: lazy(() => import('./Deposit'))
  },
  {
    key: 'withdrawal',
    path: withdrawal,
    component: lazy(() => import('./Withdrawal'))
  },
  {
    key: 'charge',
    path: charge,
    component: lazy(() => import('./Charge'))
  },
  {
    key: 'purchases',
    path: purchases,
    component: lazy(() => import('./Purchases'))
  },
  {
    key: 'transactions',
    path: transactions,
    component: lazy(() => import('./Transactions'))
  },
  {
    key: 'movements',
    path: movements,
    component: lazy(() => import('./Movements'))
  },
  {
    key: 'account',
    path: account,
    component: lazy(() => import('./Account'))
  },
  {
    key: 'preferences',
    path: preferences,
    component: lazy(() => import('./Preferences'))
  },
  {
    key: 'home',
    path: home,
    component: lazy(() => import('./Home'))
  }
];

export default routes;