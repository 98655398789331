import React from 'react';
import routes from './routes';
import menu from './menu';
import SideMenu from 'components/SideMenu';
import RouteManager from 'components/RouteManager';

export default function Pages () {
  return (
    <SideMenu data={menu}>
      <RouteManager routes={routes} />
    </SideMenu>
  );
}
