import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import { roomDetail } from 'pages/Bingo/routes';

const Accordion = withStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: theme.palette.secondary.contrastText,
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {}
}))(MuiAccordion);

const AccordionSummary = withStyles(theme => ({
  root: {
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {},
  expandIcon: {
    color: theme.palette.secondary.contrastText
  }
}))(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    paddingTop: 0,
    flexDirection: 'column'
  }
})(MuiAccordionDetails);

function SideMenuNotificationsComponent ({ data, onClick }) {
  const classes = useStyles();

  if (!data) {
    return null;
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="notifications-content"
        id="notifications-header"
      >
        <Typography className={classes.notificationTitle} color="inherit">
          <Badge badgeContent={data.length} color="primary" className={classes.notificationIcon}>
            <NotificationsIcon />
          </Badge>
          Notificações
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {data.map(({ id, content, roomId }) => (
          <Typography
            key={id}
            className={classes.notification}
            component={Link}
            to={roomDetail(roomId)}
            onClick={onClick}
          >
            {content}
          </Typography>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

SideMenuNotificationsComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      content: PropTypes.string.isRequired,
      roomId: PropTypes.any
    })
  ),
  onClick: PropTypes.func
};

export default memo(SideMenuNotificationsComponent);
