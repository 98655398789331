import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import BalanceIcon from '@material-ui/icons/AccountBalanceWallet';
import useAuth from 'hooks/useAuth';
import useStyles from './styles';
import { Link } from 'react-router-dom';
import { signIn } from 'pages/routes';
import { home } from 'pages/Wallet/routes';
import { toMoney } from 'helpers/format';
function SideMenuHeaderComponent ({ onClick }) {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const [walletAmount, setWalletAmount] = useState(currentUser?.Wallet.amount);

  useEffect(() => {
    setWalletAmount(currentUser?.Wallet.amount);
  }, [currentUser?.Wallet]);

  return (
    <div className={classes.header}>
      <Avatar className={classes.avatar} src={currentUser?.avatar} />
      {currentUser ? (
        <>
          <Typography className={classes.userName}>Olá, {currentUser.name}</Typography>
          <Button
            variant="contained"
            startIcon={<BalanceIcon />}
            className={classes.userBalance}
            component={Link}
            to={home}
            onClick={onClick}
          >
            <span>{toMoney(walletAmount)}</span>
          </Button>
        </>
      ) : (
        <Button
          size="small"
          color="inherit"
          variant="outlined"
          component={Link}
          to={signIn}
          onClick={onClick}
        >
          Entrar
        </Button>
      )}
    </div>
  );
}

SideMenuHeaderComponent.propTypes = {
  onClick: PropTypes.func
};

export default memo(SideMenuHeaderComponent);