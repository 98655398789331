import React, { memo, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import { Link, useHistory } from 'react-router-dom';
import { signIn } from 'pages/routes';
import useAuth from 'hooks/useAuth';
import useAppBar from 'hooks/useAppBar';
import logo from 'assets/logo.png';
import useStyles from './styles';
import BalanceIcon from '@material-ui/icons/AccountBalanceWallet';
import { home } from 'pages/Wallet/routes';
import { toMoney } from 'helpers/format';
import { Box } from '@material-ui/core';

function AppBarComponent ({ onClick }) {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const history = useHistory();
  const { pathname, state }  = history.location;
  const { parent, callToAction, header, title } = useAppBar();
  const [walletAmount, setWalletAmount] = useState(currentUser?.Wallet.amount);

  useEffect(() => {
    setWalletAmount(currentUser?.Wallet.amount);
  }, [currentUser?.Wallet]);

  const content = useMemo(() => {
    if (currentUser) {
      if (callToAction?.length) {
        return callToAction.map(({ label, ...props }) => (
          <Button
            key={label}
            color="primary"
            variant="contained"
            className={classes.btn}
            {...props}
          >
            {label}
          </Button>
        ));
      }
      if (header) {
        return null;
      }
      return (
        <>
          <Box>
            <Typography className={classes.userName}>
              Olá, {currentUser.name}
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              startIcon={<BalanceIcon />}
              className={classes.userBalanceBtn}
              component={Link}
              to={home}
            >
              <span>{toMoney(walletAmount)}</span>
            </Button>
          </Box>
        </>
      );
    }
    if (pathname !== signIn) {
      return <Button color="inherit" component={Link} to={signIn}>Login</Button>;
    }
    return null;
  }, [currentUser, pathname, callToAction, header, classes, walletAmount]);

  const handleBack = () => {
    if (state?.useBrowserBack) {
      history.goBack();
    } else {
      history.push(parent);
    }
  };

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {parent || state?.useBrowserBack ? (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="voltar"
              onClick={handleBack}
            >
              <BackIcon />
            </IconButton>
          ) : null }
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={onClick}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.header}>
            <img src={logo} alt="PNR" className={classes.logo} />
            {!!title && (
              <Typography className={classnames(classes.title, !!callToAction && classes.hide)}>{title}</Typography>
            )}
          </div>
          <div className={classes.grow}>{header}</div>
          <div className={classes.content}>{content}</div>
        </Toolbar>
      </AppBar>
      <Toolbar />
      {!!title && !!callToAction && <Typography className={classes.heading}>{title}</Typography>}
    </>
  );
}

AppBarComponent.propTypes = {
  onClick: PropTypes.func
};

export default memo(AppBarComponent);
