import React from 'react';
// import { sports, bicho, casino } from './routes';
import { home } from './Bingo/routes';
// import SportsIcon from 'assets/icons/sports.svg';
import BingoIcon from 'assets/icons/bingo.svg';
// import BichoIcon from 'assets/icons/bicho.svg';
// import CasinoIcon from 'assets/icons/casino.svg';

const menu = [
  // {
  //   key: 'sports',
  //   label: 'Esportes',
  //   path: sports,
  //   icon: <img src={SportsIcon} alt="Esportes" />
  // },
  {
    key: 'bingo',
    label: 'Live Bingo',
    path: home,
    icon: <img src={BingoIcon} alt="Live Bingo" />
  }
  // {
  //   key: 'bicho',
  //   label: 'Loteria Popular',
  //   path: bicho,
  //   icon: <img src={BichoIcon} alt="Loteria Popular" />
  // },
  // {
  //   key: 'casino',
  //   label: 'Cassino',
  //   path: casino,
  //   icon: <img src={CasinoIcon} alt="Cassino" />
  // }
];

export default menu;