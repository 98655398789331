import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';

const Styled = styled.div(compose(spacing));

export default function CenterComponent ({ children, force, height, ...other }) {
  const classes = useStyles({ height, force });

  return (
    <Styled {...other} className={classes.root}>
      {children}
    </Styled>
  );
}

CenterComponent.propTypes = {
  children: PropTypes.node.isRequired,
  force: PropTypes.bool,
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
