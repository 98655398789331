import { useState, useCallback } from 'react';
import Provider from '.';

export default function useBingoAccumulated () {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [accumulatedResult, setAccumulatedResult] = useState();

  const updateAccumulated = useCallback(async params => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const response = await Provider.put('/accumulated', params);
      setAccumulatedResult(response);
      return response;
    } catch (err) {
      setErrorMessage(err?.message);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    errorMessage,
    accumulatedResult,
    updateAccumulated
  };
}