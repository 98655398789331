import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    zIndex: `${theme.zIndex.modal - 2} !important`
  },
  content: {
    paddingBottom: theme.spacing(3)
  },
  header: {
    textAlign: 'center',
    position: 'relative',
    marginTop: -60,
    marginBottom: theme.spacing()
  },
  banner: {
    height: 70
  },
  title: {
    position: 'absolute',
    width: '100%',
    color: 'white',
    top: '50%',
    marginTop: -28,
    fontSize: theme.typography.pxToRem(26),
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    lineHeight: 1
  },
  label: {
    marginBottom: 4,
    fontSize: theme.typography.pxToRem(23),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center'
  },
  prize: {
    fontSize: theme.typography.pxToRem(36),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(),
    textAlign: 'center'
  },
  quadra: {
    '& $label, & $prize': {
      color: '#FE5418'
    }
  },
  linha: {
    '& $label, & $prize': {
      color: '#0D7AD9'
    }
  },
  bingo: {
    '& $label, & $prize': {
      color: '#12A60D'
    }
  },
  overflow: {
    overflow: 'visible'
  }
}));
