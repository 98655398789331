import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: ({ force, height = '100%' }) => force ? 'calc(100vh - 64px)' : height,
    width: '100%'
  }
});
