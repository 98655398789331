import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: ({ height }) => height
  },
  slides: {
    display: 'flex',
    overflowX: ({ forceAutoNavigate }) => forceAutoNavigate ? 'hidden' : 'auto',
    scrollSnapType: 'x mandatory',
    scrollBehavior: 'smooth',
    overflowScroTing: 'touch',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  slide: {
    width: '100%',
    scrollSnapAlign: 'start',
    flexShrink: 0,
    transformOrigin: 'center center',
    transform: 'scale(1)',
    transition: 'transform 0.5s',
    '& + &': {
      marginLeft: ({ margin }) => theme.spacing(margin)
    }
  },
  active: {
    '$dots > li&': {
      backgroundColor: ({ light, delay }) => delay ? 'none' : light ? 'white' : 'black',
      width: ({ size }) => size * 3
    }
  },
  dots: {
    margin: 0,
    padding: ({ size }) => `${size}px 0 0 0`,
    display: 'flex',
    justifyContent: 'center',
    listStyleType: 'none',
    '& > li': {
      transition: 'width 0.3s',
      cursor: 'pointer',
      overflow: 'hidden',
      position: 'relative',
      width: ({ size }) => size,
      height: ({ size }) => size,
      borderRadius: ({ size }) => size,
      backgroundColor: ({ light }) => light ? 'rgba(255, 255, 255, .4)' : 'rgba(0, 0, 0, .3)',
      '& + *': {
        marginLeft: ({ size }) => size / 2
      }
    }
  },
  countdown: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    backgroundColor: ({ light, color }) => color || (light ? 'rgba(255, 255, 255, .7)' : 'rgba(0, 0, 0, .6)')
  },
  picker: {
    marginTop: theme.spacing()
  }
}));
