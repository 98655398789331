import { createMuiTheme } from '@material-ui/core/styles';

const ball = ['#D96E11'];

export default createMuiTheme({
  palette: {
    primary: {
      main: '#17A50C'
    },
    secondary: {
      main: '#2680EB'
    },
    background: {
      default: '#FFFFFF'
    },
    common: {
      black: '#191919'
    },
    custom: {
      card: '#042E53',
      ball,
      getBallColor: (value, max) => ball[Math.ceil(parseInt(value) / (max / ball.length)) - 1],
      acumulado: {
        light: '#CDCECD',
        main: '#E8C900',
        dark: '#C2A900',
        card: '#5C5000',
        number: '#DBBE00'
      },
      bingo: {
        light: '#CDCECD',
        main: '#54B12D',
        dark: '#12A60D',
        card: '#023600',
        number: '#12A60D'
      },
      linha: {
        light: '#DFE0DF',
        main: '#2676D2',
        dark: '#055ABC',
        card: '#042E53',
        number: '#0D7AD9'
      },
      quadra: {
        light: '#B9B9B9',
        main: '#F57C28',
        dark: '#D96E11',
        card: '#882503',
        number: '#FE5418'
      }
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
        fontWeight: 700
      }
    }
  }
});
