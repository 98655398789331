import React from 'react';
import PropTypes from 'prop-types';

export default function StripComponent ({ main, dark, black, height, width }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 438.174 111.466">
      <path d="M-1303.578,4905.4s26.677,12.036,51.492,18.838a274.13,274.13,0,0,0,47.768,8.372l2.821,64.138s-26.887,2.335-52.946-2.588-49.135-16.092-49.135-16.092l17.694-28.841s-3.842-10.888-8.265-21.844S-1303.578,4905.4-1303.578,4905.4Z" transform="translate(1303.578 -4885.831)" fill={dark} />
      <path d="M-1201.5,4905.4s-26.677,12.036-51.492,18.838a274.128,274.128,0,0,1-47.768,8.372l-2.821,64.138s26.887,2.335,52.946-2.588,49.135-16.092,49.135-16.092l-17.694-28.841s3.842-10.888,8.265-21.844S-1201.5,4905.4-1201.5,4905.4Z" transform="translate(1639.67 -4885.831)" fill={dark} />
      <path d="M-1260.766,4961.593a121.754,121.754,0,0,0,22.336,15.711,139.643,139.643,0,0,0,26.61,10.12V4944.7Z" transform="translate(1313.969 -4876.292)" fill={black} />
      <path d="M-1211.82,4961.593a121.756,121.756,0,0,1-22.336,15.711,139.646,139.646,0,0,1-26.61,10.12V4944.7Z" transform="translate(1596.79 -4876.292)" fill={black} />
      <path d="M-1260.766,4902.61v71.956s40.75-7.454,82.314-10.785,83.94-2.539,83.94-2.539v-71.589s-45.483.12-87.046,3.359S-1260.766,4902.61-1260.766,4902.61Z" transform="translate(1313.969 -4889.652)" fill={main} />
      <path d="M-1094.512,4902.61v71.956s-40.75-7.454-82.314-10.785-83.94-2.539-83.94-2.539v-71.589s45.483.12,87.046,3.359S-1094.512,4902.61-1094.512,4902.61Z" transform="translate(1479.482 -4889.652)" fill={main} />
    </svg>
  );
}

StripComponent.propTypes = {
  main: PropTypes.string,
  dark: PropTypes.string,
  black: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};
