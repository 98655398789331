import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.common.black
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2)
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  grow: {
    flex: 1
  },
  logo: {
    height: 40
  },
  title: {
    marginLeft: theme.spacing(2),
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16)
    }
  },
  heading: {
    padding: theme.spacing(),
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#2F2F2F',
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(20),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  btn: {
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: theme.typography.pxToRem(12),
    '& + &': {
      marginLeft: theme.spacing()
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 12,
      paddingRight: 12
    }
  },
  userName: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  content: {
    display: 'flex',
    alignItems: 'center'
  },
  hide: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  userBalanceBtn: {
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: theme.typography.pxToRem(12),
    marginLeft: 10,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 12,
      paddingRight: 12
    }
  }
}));