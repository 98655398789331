import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { signIn } from 'pages/routes';
import { home } from 'pages/Bingo/routes';
import Loader from 'components/Loader';

const renderRoute = ({
  authenticated,
  user,
  component: Component,
  ...config
}) => (
  <Route exact {...config} render={props => {
    const { location } = props;
    if (authenticated && !user) {
      return <Redirect to={{ pathname: signIn, state: { from: location } }} />;
    }
    if (authenticated === false && user) {
      const to = location.state?.from || { pathname: home };
      return <Redirect to={to} />;
    }
    return <Component {...props} />;
  }} />
);

export default function RouteManagerComponent ({ routes }) {
  const { currentUser: user } = useAuth();
  return (
    <Suspense fallback={<Loader forceCenter />}>
      <Switch>
        {routes.map(route => renderRoute({ ...route, user }))}
      </Switch>
    </Suspense>
  );
}

RouteManagerComponent.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.any.isRequired,
      authenticated: PropTypes.bool
    })
  ).isRequired
};
