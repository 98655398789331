import { lazy } from 'react';
import { paths as bingoPaths } from './Bingo/routes';
import { paths as walletPaths } from './Wallet/routes';

export const signIn = '/entrar';
export const signUp = '/cadastro';
export const recoverPassword = '/alterar-senha';
export const home = '/';
export const sports = '/esportes';
export const bicho = '/loteria-popular';
export const casino = '/cassino';
export const maintenance = '/aguarde';

const routes = [
  {
    key: 'signIn',
    path: signIn,
    authenticated: false,
    component: lazy(() => import('./SignIn'))
  },
  {
    key: 'signUp',
    path: [`${signUp}/:code`, signUp],
    authenticated: false,
    component: lazy(() => import('./SignUp'))
  },
  {
    key: 'recoverPassword',
    path: recoverPassword,
    authenticated: false,
    component: lazy(() => import('./RecoverPassword'))
  },
  {
    key: 'sports',
    path: sports,
    authenticated: true,
    component: lazy(() => import('./Sports'))
  },
  {
    key: 'bingo',
    path: bingoPaths,
    authenticated: true,
    component: lazy(() => import('./Bingo'))
  },
  {
    key: 'bicho',
    path: bicho,
    authenticated: true,
    component: lazy(() => import('./Bicho'))
  },
  {
    key: 'wallet',
    path: walletPaths,
    authenticated: true,
    component: lazy(() => import('./Wallet'))
  },
  {
    key: 'casino',
    path: casino,
    authenticated: true,
    component: lazy(() => import('./Casino'))
  },
  {
    key: 'home',
    path: home,
    component: lazy(() => import('./Home'))
  },
  {
    key: 'maintenance',
    path: maintenance,
    component: lazy(() => import('./Maintenance'))
  },
  {
    key: 'notFound',
    component: lazy(() => import('./NotFound'))
  }
];

export default routes;