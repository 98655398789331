import { useState, useEffect } from 'react';

export default function useFocus (initialState = true) {
  const [hasFocus, setHasFocus] = useState(initialState);

  useEffect(() => {
    const handler = () => setHasFocus(!document.hidden);
    document.addEventListener('visibilitychange', handler);
    return () => document.removeEventListener('visibilitychange', handler);
  }, []);

  return hasFocus;
};
