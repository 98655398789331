import sortBy from 'lodash/sortBy';

export const sortCards = (cards, match, cardX = 5, cardY = 3) => {
  const isBingo = !!match?.MatchAwards?.find(ma => ma.name === 'Bingo' && ma.status === 'start');
  const balls = match?.sortedBalls?.split(',');
  return sortBy(cards, card => {
    if (isBingo) {
      const sortedBallsLength = card?.CardNumbers?.filter(cn => balls?.includes(String(cn.value))).length;
      return cardX * cardY - sortedBallsLength;
    }

    const lengths = card?.CardNumbers?.reduce((result, val) => {
      if (balls?.includes(String(val.value))) {
        const count = result[val.positionY] || cardX;
        result[val.positionY] = count - 1;
      }
      return result;
    }, new Array(cardY).fill(cardX)).sort();

    return lengths;
  });
};

export const simpleSortCards = (cards, balls) => {
  return sortBy(cards, card => {
    const sortedBallsLength = card?.CardNumbers?.filter(cn => balls?.includes(String(cn.value))).length;
    return card?.CardNumbers?.length - sortedBallsLength;
  });
};
