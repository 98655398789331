import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import useStyles from './styles';

export default function NumberPickerComponent ({ value, min, max, step, onChange, delay, next, prev, large }) {
  const classes = useStyles({ large });
  const [currentValue, setCurrentValue] = useState(value);
  const [timer, setTimer] = useState(null);

  const handleSubtract = () => {
    onChange(value - step);
  };
  const handleAdd = () => {
    onChange(value + step);
  };

  const parseValue = useCallback(val => {
    let number = parseInt(val);
    number = Math.max(number, min);
    if (max) {
      number = Math.min(number, max);
    }
    return number;
  }, [max, min]);

  const handleChange = evt => {
    clearTimeout(timer);
    const number = parseValue(evt.target.value);
    console.log(number, evt.target.value);
    setCurrentValue(number);
    if (!isNaN(number)) {
      const timeout = setTimeout(() => onChange(number), delay * 1000);
      setTimer(timeout);
    }
  };

  const handleFocus = () => setCurrentValue('');
  const handleBlur = () => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
  };

  useEffect(() => {
    const number = parseValue(value);
    if (number !== value) {
      onChange(number);
    }
    setCurrentValue(number);
  }, [value, parseValue, onChange]);

  return (
    <div className={classes.root}>
      {!!prev && (
        <button className={classes.button} disabled={value <= min} onClick={handleSubtract}>
          {prev}
        </button>
      )}
      <input
        className={classes.input}
        value={currentValue || ''}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {!!max && (
        <Typography className={classes.max}>/{max}</Typography>
      )}
      {!!next && (
        <button className={classes.button} disabled={value >= max} onClick={handleAdd}>
          {next}
        </button>
      )}
    </div>
  );
}

NumberPickerComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  delay: PropTypes.number,
  next: PropTypes.node,
  prev: PropTypes.node,
  large: PropTypes.bool
};

NumberPickerComponent.defaultProps = {
  step: 1,
  min: 1,
  delay: 1,
  prev: <RemoveIcon />,
  next: <AddIcon />
};
