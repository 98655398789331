import React, { memo, Children, useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'clsx';
import PrevIcon from '@material-ui/icons/ChevronLeft';
import NextIcon from '@material-ui/icons/ChevronRight';
import NumberPicker from 'components/NumberPicker';
import Countdown from './countdown';
import useStyles from './styles';
import debounce from 'lodash/debounce';
import { times, isParent } from 'helpers/misc';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { compose, spacing, sizing } from '@material-ui/system';

const Styled = styled.div(compose(spacing, sizing));

const SCROLL_DEBOUNCE = 100;
const START_PAGE = 1;

function CarouselComponent ({ children, delay, light, size, variant, margin, height, color, forceAutoNavigate, ...other }) {
  const classes = useStyles({ light, size, margin, forceAutoNavigate, height, delay, color });
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(1);
  const [focus, setFocus] = useState(false);
  const pages = useMemo(() => times(Children.count(children), START_PAGE), [children]);
  const container = useRef();
  const slides = useRef();
  const theme = useTheme();

  useEffect(() => {
    if (delay && !focus && pages.length > 1) {
      const interval = setInterval(() => {
        setNextPage(prev => prev >= pages.length ? START_PAGE : prev + 1);
      }, delay * 1000);
      return () => clearInterval(interval);
    }
  }, [delay, page, pages, focus]);

  useEffect(() => {
    slides.current?.scrollTo({
      left: container.current?.clientWidth * (nextPage - START_PAGE),
      behavior: 'smooth'
    });
  }, [nextPage]);

  useEffect(() => {
    const current = slides.current;
    if (current) {
      const onScroll = debounce(evt => {
        const pos = evt.target.scrollLeft;
        const width = container.current?.clientWidth + theme.spacing(margin);
        const currentPage = Math.round((pos / width) + START_PAGE);
        setPage(currentPage);
        setNextPage(currentPage);
      }, SCROLL_DEBOUNCE);
      current.addEventListener('scroll', onScroll);
      return () => {
        current.removeEventListener('scroll', onScroll);
      };
    }
  }, [margin, slides, theme]);

  const handleMouseEnter = evt => {
    if (!isParent(container.current, evt.relatedTarget) && !forceAutoNavigate) {
      setFocus(true);
    }
  };

  const handleMouseLeave = evt => {
    if (!isParent(container.current, evt.relatedTarget) && !forceAutoNavigate) {
      setFocus(false);
    }
  };

  const paginate = useMemo(() => {
    if (pages.length <= 1) {
      return null;
    }
    switch (variant) {
      case 'dots':
        return (
          <ul className={classes.dots}>
            {pages.map(i => (
              <li
                key={i}
                className={classnames(page === i && classes.active)}
                onClick={() => setNextPage(i)}
              >
                <Countdown
                  className={classes.countdown}
                  stop={focus}
                  duration={page === i ? delay : 0}
                />
              </li>
            ))}
          </ul>
        );
      case 'picker':
        return (
          <div className={classes.picker}>
            <NumberPicker
              value={page}
              onChange={setNextPage}
              max={pages.length}
              prev={<PrevIcon />}
              next={<NextIcon />}
            />
          </div>
        );
      default:
        return null;
    }
  }, [pages, variant, classes, focus, page, delay]);

  return (
    <Styled
      className={classes.root}
      {...other}
      ref={container}
      onMouseOver={handleMouseEnter}
      onMouseOut={handleMouseLeave}
    >
      <div className={classes.slides} ref={slides}>
        {Children.map(children, child => <div className={classes.slide}>{child}</div>)}
      </div>
      {paginate}
    </Styled>
  );
};

CarouselComponent.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['dots', 'picker', 'none']),
  delay: PropTypes.number,
  light: PropTypes.bool,
  size: PropTypes.number,
  margin: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  forceAutoNavigate: PropTypes.bool
};

CarouselComponent.defaultProps = {
  size: 11,
  variant: 'dots',
  margin: 0
};

export default memo(CarouselComponent);
