import React, { createContext, useContext, useCallback } from 'react';
import pusher from 'config/push';

const SocketContext = createContext();

const CHANNELS = {};

export const SocketProvider = props => {
  const listen = useCallback((channelName, callback, key) => {
    if (typeof channelName !== 'string' || typeof callback !== 'function') {
      return;
    }

    let events = CHANNELS[channelName];

    // cria o canal para a primeiro listener
    if (!events) {
      CHANNELS[channelName] = events = [];
      const socket = pusher.subscribe(channelName);
      socket.bind_global((eventName, data) => {
        if (process.env.NODE_ENV === 'development') {
          console.log('socket', channelName, eventName, data);
        }
        if (eventName !== 'pusher:subscription_succeeded') {
          events.forEach(({ callback: cb }) => cb(eventName, data));
        }
      });
    }

    const event = { callback, key };
    events.push(event);

    return () => {
      const index = events.findIndex(e => e === event);
      if (index >= 0) {
        events.splice(index, 1);
        // remove o canal na última listener
        if (!events.length) {
          pusher.unsubscribe(channelName);
          delete CHANNELS[channelName];
        }
      }
    };
  }, []);

  return <SocketContext.Provider {...props} value={listen} />;
};

const useSocket = () => {
  const context = useContext(SocketContext);

  if (context === undefined) {
    throw new Error('useSocket must be used within a SocketProvider');
  }

  return context;
};

export default useSocket;
