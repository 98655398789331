import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import useAuth from '../../hooks/useAuth';
import axios from 'axios';

export default function ShareComponent (props) {

  const currentURL = window.location.hostname;

  const BackOfficeUrl =
  currentURL == 'localhost' ?  'https://i5qy580i01.execute-api.us-east-1.amazonaws.com/v1' :
    currentURL == 'pnr-web-dev.s3-website-us-east-1.amazonaws.com' ? 'https://i5qy580i01.execute-api.us-east-1.amazonaws.com/v1' :
      'https://rws1xbxfkf.execute-api.us-east-1.amazonaws.com/v1';

  const token = window.localStorage.getItem('@PNR:TOKEN');

  const provider = axios.create({
    baseURL: BackOfficeUrl,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': token
    }
  });

  const { currentUser } = useAuth();
  const [socialMedia, setSocialMedia] = useState();
  const user = currentUser?.partnerId ? provider.get(`/partners/${currentUser?.partnerId}`) : '';

  useEffect(() => {function setMedia () {
    user.then(data => {
      setSocialMedia(data);
    });
  };
  setMedia();
  // eslint-disable-next-line
  }, []);

  const facebook = socialMedia?.data.facebookURL ? socialMedia?.data.facebookURL : 'https://facebook.com/palpitenarede';
  const instagram = socialMedia?.data.instagramURL ? socialMedia?.data.instagramURL : 'https://instagram.com/palpitenarede';

  return (
    <Box {...props}>
      <Grid container alignItems="center" justify="center" spacing={2}>
        <Grid item>
          <Typography variant="caption">Siga-nos</Typography>
        </Grid>
        <Grid item>
          <IconButton color="primary" component="a" href={instagram} target="blank">
            <InstagramIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton color="primary" component="a" href={facebook} target="blank">
            <FacebookIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}