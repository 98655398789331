import moment from 'moment';

export const rand = (max, min = 0) => parseInt(Math.random() * (max - min)) + min;

export const times = (len, start = 0, parse) => {
  const array = [];
  for (let i = start; i < len + start; i++) {
    array.push(typeof parse === 'function' ? parse(i) : parse ?? i);
  }
  return array;
};

export const getVideoDimensions = width => {
  return {
    width,
    height: width * 9 / 16
  };
};

export const isEmailValid = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isParent = (refNode, otherNode) => {
  if (!otherNode || !refNode) {
    return false;
  }
  var parent = otherNode?.parentNode;
  do {
    if (refNode == parent) {
      return true;
    } else {
      parent = parent?.parentNode;
    }
  } while (parent);
  return false;
};

export const getDateRange = data => {
  switch (data) {
    case 'today':
      return {
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day')
      };
    case 'tomorrow':
      return {
        startDate: moment().add(1, 'day').startOf('day'),
        endDate: moment().add(1, 'day').endOf('day')
      };
    case 'yesterday':
      return {
        startDate: moment().subtract(1, 'day').startOf('day'),
        endDate: moment().subtract(1, 'day').endOf('day')
      };
    case 'week':
      return {
        startDate: moment().startOf('week'),
        endDate: moment().endOf('week')
      };
    default:
      if (moment(data).isValid()) {
        return {
          startDate: moment(data).startOf('day'),
          endDate: moment(data).endOf('day')
        };
      }
  };
};